import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { KontaktModalComponent } from '../kontakt-modal/kontakt-modal.component';
import { KontaktSheetComponent } from '../kontakt-sheet/kontakt-sheet.component';
import { MatIconModule } from '@angular/material/icon';
import { DOCUMENT } from '@angular/common';
import { NgIconComponent } from '@ng-icons/core';

@Component({
  selector: 'app-kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.scss'],
  standalone: true,
  imports: [MatIconModule, NgIconComponent],
})
export class KontaktComponent {
  constructor(
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public openModal() {
    this.dialog.open(KontaktModalComponent);
  }

  public openSheet() {
    this.bottomSheet.open(KontaktSheetComponent);
  }

  public mailTo() {
    const hidden = 'Wm5NdGFXNW1iMEIxYm1rdGEyOWxiRzR1WkdVPQ==';
    const mail = atob(atob(hidden));
    console.log(mail);
    const a = this.document.createElement('a');
    a.target = '_blank';
    a.referrerPolicy = 'no-referrer';
    a.rel = 'noreferrer';
    a.href = `mailto:${mail}`;
    a.click();
  }
}
