import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MarkdownComponent } from '../markdown/markdown.component';
import { MatIconModule } from '@angular/material/icon';
import { Studycontent } from 'src/app/data/types';
import { NgIconComponent } from '@ng-icons/core';

@Component({
  selector: 'app-study-modal',
  templateUrl: './study-modal.component.html',
  styleUrls: ['./study-modal.component.scss'],
  standalone: true,
  imports: [MatIconModule, MarkdownComponent, NgIconComponent],
})
export class StudyModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public inhalt: Studycontent) {}

  get tabLink() {
    return `${this.inhalt.sluggedTitle}`;
  }
}
