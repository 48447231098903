import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { NgIconComponent } from '@ng-icons/core';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
  standalone: true,
  imports: [RouterLink, MatIconModule, NgIconComponent],
})
export class HeroComponent {
  @Input('studiumElement')
  private studiumElement: HTMLDivElement | undefined;

  public scrollToStudium() {
    if (!this.studiumElement) return;
    this.studiumElement.scrollIntoView();
  }
}
