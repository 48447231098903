import { Component, Input, OnChanges, SimpleChange, SimpleChanges, ElementRef } from '@angular/core';
import { MarkdownService } from '../../services/markdown.service';

@Component({
    selector: 'markdown',
    templateUrl: './markdown.component.html',
    styleUrls: ['./markdown.component.scss'],
    standalone: true,
})
export class MarkdownComponent implements OnChanges {
  @Input('content') public content: string | undefined;

  public renderedContent = '';

  constructor(private md: MarkdownService, private ref: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    const content = changes.content;
    if (content instanceof SimpleChange && content.currentValue) {
      this.md.render(content.currentValue).then((v) => {
        (this.ref.nativeElement as HTMLElement).innerHTML = v;
      });      
    }
  }
}
