import { Component, ViewContainerRef, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { toObservable } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';

import { EventSelectionComponent } from '../event-selection/event-selection.component';
import { FormatTimeStringPipe } from '../../format-time-string.pipe';
import { MarkdownComponent } from '../markdown/markdown.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MatTabsModule } from '@angular/material/tabs';
import { NgIf, NgFor, AsyncPipe, DatePipe } from '@angular/common';
import { ContentService } from 'src/app/services/content.service';
import { firstValueFrom } from 'rxjs';
import { Event } from 'src/app/data/types';
import { CorrectImageUrlPipe } from '../../correct-image-url.pipe';
import { NgIconComponent } from '@ng-icons/core';
import { getWindow } from 'src/app/helpers/window';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    MatTabsModule,
    LazyLoadImageModule,
    MatIconModule,
    MatButtonModule,
    MarkdownComponent,
    AsyncPipe,
    DatePipe,
    FormatTimeStringPipe,
    CorrectImageUrlPipe,
    NgIconComponent,
  ],
})
export class EventsComponent {
  #content = inject(ContentService);

  public selectedIndex = 0;

  events$ = toObservable(this.#content.events);

  constructor(
    private bottomSheet: MatBottomSheet,
    private ref: ViewContainerRef
  ) {
    this.upcomingEvents$.subscribe((events) => {
      if (events.length <= 0) return;
      this.selectEvent(events.length - 1, false);
    });
  }

  get upcomingEvents$() {
    return this.events$.pipe(map((events) => events.filter((event) => !this.isPastEvent(event))));
  }

  get offset$() {
    return this.upcomingEvents$.pipe(map((events) => events.length));
  }

  get pastEvents$() {
    return this.events$.pipe(map((events) => events.filter((event) => this.isPastEvent(event))));
  }

  get isFirstEvent$() {
    return this.selectedIndex === 0;
  }

  get isLastEvent$() {
    return this.events$.pipe(map((events) => this.selectedIndex === events.length - 1));
  }

  get isPastEvent$() {
    return this.events$.pipe(
      map((events) => {
        const currentEvent = events[this.selectedIndex];
        return this.isPastEvent(currentEvent);
      })
    );
  }

  public isPastEvent(event: Event): boolean {
    const dateTime = Date.parse(`${event.day} ${event.time}`);
    return dateTime < Date.now();
  }

  private scrollToEvents() {
    getWindow()?.requestAnimationFrame(() => {
      const el = this.ref.element.nativeElement as HTMLElement;
      el.scrollIntoView();
    });
  }

  public selectEvent(index: number, shouldScroll = true) {
    this.selectedIndex = index;
    if (shouldScroll) this.scrollToEvents();
  }

  public async openEventSelection() {
    this.bottomSheet
      .open(EventSelectionComponent, {
        data: firstValueFrom(this.events$),
      })
      .afterDismissed()
      .toPromise()
      .then((result: number | undefined) => {
        if (result === undefined) return;
        this.selectEvent(result);
      });
  }
}
