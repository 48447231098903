import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatTimeString',
    standalone: true,
})
export class FormatTimeStringPipe implements PipeTransform {
  /**
   * Takes a HH:mm:ss.SSS time string and turns it into HH:mm
   */
  transform(value: string): string {
    return value.split(':').slice(0, 2).join(':');
  }
}
