import { Component, Inject, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { fromEvent, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

import { MarkdownComponent } from '../markdown/markdown.component';
import { MatIconModule } from '@angular/material/icon';
import { Studycontent } from 'src/app/data/types';
import { NgIconComponent } from '@ng-icons/core';

@Component({
  selector: 'app-study-sheet',
  templateUrl: './study-sheet.component.html',
  styleUrls: ['./study-sheet.component.scss'],
  standalone: true,
  imports: [MatIconModule, MarkdownComponent, NgIconComponent],
})
export class StudySheetComponent implements OnInit, OnDestroy {
  private isAtScrollTop = true;
  private touchStart = 0;
  private subscriptions = new Array<Subscription>();

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public inhalt: Studycontent,
    private ref: ViewContainerRef,
    public bottomRef: MatBottomSheetRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  get tabLink() {
    return `${this.inhalt.sluggedTitle}`;
  }

  get refElement() {
    return this.ref.element.nativeElement as HTMLElement;
  }

  get markdownScrollTop() {
    return this.refElement.querySelector('markdown.content')?.scrollTop || 0;
  }

  public close(event: MouseEvent, title: HTMLElement) {
    if (event.target === title) this.bottomRef.dismiss();
  }

  ngOnInit() {
    this.subscriptions.push(
      fromEvent<TouchEvent>(this.document, 'touchstart').subscribe((evt) => {
        if ((evt.target as HTMLElement)?.classList.contains('cdk-overlay-backdrop'))
          this.bottomRef.dismiss();
      }),
      fromEvent<TouchEvent>(this.ref.element.nativeElement, 'touchstart').subscribe((evt) => {
        this.touchStart = evt.changedTouches.item(0)?.pageY || 0;
      }),
      fromEvent<TouchEvent>(this.ref.element.nativeElement, 'touchmove')
        .pipe(throttleTime(50))
        .subscribe((evt) => {
          const touchMove = evt.changedTouches.item(0)?.pageY || 0;
          if (touchMove > this.touchStart && this.isAtScrollTop) this.bottomRef.dismiss();
          else this.isAtScrollTop = false;
        }),
      fromEvent<TouchEvent>(this.ref.element.nativeElement, 'touchend').subscribe(() => {
        this.isAtScrollTop = this.markdownScrollTop === 0;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
