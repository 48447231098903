import { Component, AfterViewInit, ElementRef, ViewChild, Inject, viewChild } from '@angular/core';
import { DOCUMENT, AsyncPipe } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, fromEvent, merge, of } from 'rxjs';
import { throttleTime, filter, map } from 'rxjs/operators';
import { KontaktComponent } from 'src/app/components/kontakt/kontakt.component';
import { MitgliederComponent } from 'src/app/components/mitglieder/mitglieder.component';
import { EventsComponent } from 'src/app/components/events/events.component';
import { StudiumComponent } from 'src/app/components/studium/studium.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { HeroComponent } from 'src/app/components/hero/hero.component';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { IsVisibleDirective } from 'src/app/directives/is-visible.directive';
import { NgIconCacheToken, NgIconComponent } from '@ng-icons/core';
import { FsLogoComponent } from '../../icons/fs-logo/fs-logo.component';
import { getWindow } from 'src/app/helpers/window';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
  standalone: true,
  imports: [
    MatToolbarModule,
    MatIconModule,
    HeroComponent,
    LazyLoadImageModule,
    StudiumComponent,
    EventsComponent,
    MitgliederComponent,
    KontaktComponent,
    AsyncPipe,
    IsVisibleDirective,
    NgIconComponent,
    FsLogoComponent,
  ],
})
export class MainPageComponent implements AfterViewInit {
  #isInView = {
    hero: new BehaviorSubject<boolean>(true),
    studium: new BehaviorSubject<boolean>(false),
    events: new BehaviorSubject<boolean>(false),
    mitglieder: new BehaviorSubject<boolean>(false),
    kontakt: new BehaviorSubject<boolean>(false),
  } as const;

  #customPlatforms = {
    SAMSUNG_BROWSER: getWindow()?.navigator?.userAgent?.includes('SamsungBrowser') ?? true,
    EDGE_MOBILE: getWindow()?.navigator?.userAgent?.includes('EdgA') ?? true,
  };

  public isSafari =
    this.platform.WEBKIT ||
    this.platform.SAFARI ||
    this.platform.EDGE ||
    this.platform.TRIDENT ||
    this.#customPlatforms.SAMSUNG_BROWSER ||
    this.#customPlatforms.EDGE_MOBILE;

  // Section Images
  public heroImg = this.isSafari
    ? '../../assets/bg_images/uniwiese_safari.jpg'
    : '../../assets/bg_images/uniwiese.jpg';

  public studiumImg = this.isSafari
    ? '../../assets/bg_images/philosophikum_safari.jpg'
    : '../../assets/bg_images/philosophikum.jpg';

  public eventsImg = this.isSafari
    ? '../../assets/bg_images/hoersaalgebaeude_safari.jpg'
    : '../../assets/bg_images/hoersaalgebaeude.jpg';

  public kontaktImg = this.isSafari
    ? '../../assets/bg_images/hauptgebaeude_safari.jpg'
    : '../../assets/bg_images/hauptgebaeude.jpg';

  scrollPercent$ = fromEvent(this.document, 'scroll').pipe(
    throttleTime(50),
    map(() => {
      const { scrollTop, scrollHeight } = this.document.documentElement;
      const window = getWindow();
      if (!window) return 0;
      return 100 * (scrollTop / (scrollHeight - window.innerHeight));
    })
  );

  constructor(
    private platform: Platform,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) {}

  isVisible(visible: boolean, target: 'hero' | 'studium' | 'events' | 'mitglieder' | 'kontakt') {
    this.#isInView[target].next(visible);
  }

  // InView Observers
  get hero$() {
    return this.#isInView.hero.asObservable();
  }

  get studium$() {
    return this.#isInView.studium.asObservable();
  }

  get events$() {
    return this.#isInView.events.asObservable();
  }

  get mitglieder$() {
    return this.#isInView.mitglieder.asObservable();
  }

  get kontakt$() {
    return this.#isInView.kontakt.asObservable();
  }

  get toolbarHidden$() {
    // Toolbar is visible if hero and kontakt are not visible
    return merge(this.hero$.pipe(filter((_) => _)), this.kontakt$.pipe(filter((_) => _)));
  }

  ngAfterViewInit(): void {
    getWindow()?.requestAnimationFrame(() => {
      let hash = this.route.snapshot.fragment!;
      if (!hash) return;
      hash = hash.split('?').shift() ?? '';
      if (!hash) return;
      const element = this.document.getElementById(hash);
      if (!element) return;
      element.scrollIntoView();
    });
  }
}
