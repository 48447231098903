import { Component, ViewContainerRef, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

import { StudySheetComponent } from '../study-sheet/study-sheet.component';
import { StudyModalComponent } from '../study-modal/study-modal.component';
import { NgFor, AsyncPipe } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { ContentService } from 'src/app/services/content.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { Studycontent } from 'src/app/data/types';

@Component({
  selector: 'app-studium',
  templateUrl: './studium.component.html',
  styleUrls: ['./studium.component.scss'],
  standalone: true,
  imports: [MatListModule, NgFor, AsyncPipe],
})
export class StudiumComponent {
  #content = inject(ContentService);
  #bottomSheet = inject(MatBottomSheet);
  #dialog = inject(MatDialog);

  studieninhalte$ = toObservable(this.#content.studyContents);

  openStudyContent(inhalt: Studycontent, target: 'mobile' | 'desktop') {
    if (target === 'mobile') {
      this.#bottomSheet.open(StudySheetComponent, {
        data: inhalt,
      });
    } else {
      this.#dialog.open(StudyModalComponent, {
        data: inhalt,
        maxHeight: '80vh',
        minWidth: '60vw',
        maxWidth: 'min(80vw, 80ch)',
      });
    }
  }
}
