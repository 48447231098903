import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ViewContainerRef,
  inject,
  viewChild,
} from '@angular/core';
import { DOCUMENT, NgFor, DatePipe, AsyncPipe } from '@angular/common';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { combineLatest, fromEvent, Subscription } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';

import { MatActionList, MatListModule } from '@angular/material/list';
import { Event } from 'src/app/data/types';

@Component({
  selector: 'app-event-selection',
  templateUrl: './event-selection.component.html',
  styleUrls: ['./event-selection.component.scss'],
  standalone: true,
  imports: [MatListModule, NgFor, DatePipe, AsyncPipe],
})
export class EventSelectionComponent {
  events$: Promise<Event[]> = inject(MAT_BOTTOM_SHEET_DATA);
  ref = inject(ViewContainerRef);
  bottomRef = inject(MatBottomSheetRef);
  #document = inject(DOCUMENT);

  get refElement() {
    return this.ref.element.nativeElement as HTMLElement;
  }

  get actionListScrollTop() {
    return this.refElement.querySelector('div.list')?.scrollTop || 0;
  }

  #touchStart$ = fromEvent<TouchEvent>(this.ref.element.nativeElement, 'touchstart').pipe(
    map((event) => event.changedTouches.item(0)?.pageY || 0)
  );
  #touchMove$ = fromEvent<TouchEvent>(this.ref.element.nativeElement, 'touchmove').pipe(
    map((event) => event.changedTouches.item(0)?.pageY || 0)
  );
  #touchEnd$ = fromEvent<TouchEvent>(this.ref.element.nativeElement, 'touchend').pipe(
    map((event) => event.changedTouches.item(0)?.pageY || 0)
  );
  #subscriptions = [
    fromEvent<TouchEvent>(this.#document, 'touchstart').subscribe((evt) => {
      if ((evt.target as HTMLElement)?.classList.contains('cdk-overlay-backdrop'))
        this.bottomRef.dismiss();
    }),
    combineLatest([this.#touchStart$, this.#touchMove$, this.#touchEnd$]).subscribe(
      ([start, move, end]) => {
        const isAtScrollTop = this.actionListScrollTop === 0;
        if (move > start && isAtScrollTop) this.bottomRef.dismiss();
      }
    ),
  ];

  ngOnInit() {}

  ngOnDestroy() {
    this.#subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
