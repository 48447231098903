import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'splitRoles',
    standalone: true,
})
export class SplitRolesPipe implements PipeTransform {
  transform(roleString?: string): string[] {
    return roleString?.split('|').map((t) => t.trim()) ?? [];
  }
}
