import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT, registerLocaleData } from '@angular/common';
import { Platform } from '@angular/cdk/platform';

import localeDe from '@angular/common/locales/de';
import { RouterOutlet } from '@angular/router';
import { provideIcons } from '@ng-icons/core';
import {
  matTitle,
  matSubtitles,
  matPlace,
  matLink,
  matEvent,
  matArrowDownward,
  matLocalLibrary,
  matGroup,
  matContactMail,
  matOpenInNew,
} from '@ng-icons/material-icons/baseline';
import {
  ionMailOutline,
  ionLogoSlack,
  ionLogoDiscord,
  ionLogoFacebook,
  ionLogoInstagram,
} from '@ng-icons/ionicons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
  viewProviders: [
    provideIcons({
      matTitle,
      matSubtitles,
      matPlace,
      matLink,
      matEvent,
      matArrowDownward,
      matLocalLibrary,
      matGroup,
      matContactMail,
      matOpenInNew,
      ionMailOutline,
      ionLogoSlack,
      ionLogoDiscord,
      ionLogoFacebook,
      ionLogoInstagram,
    }),
  ],
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    registerLocaleData(localeDe, 'de');

    for (const platformKey in this.platform) {
      const value = (this.platform as any)[platformKey];
      if (!value) continue;
      this.document.body.classList.add(platformKey.toLowerCase());
    }
  }
}
