<div class="description">
  <h1>Du hast Fragen oder suchst den <span class="underlined">Kontakt</span>?</h1>
  <h2>Schreib uns über einen unserer Kanäle!</h2>
</div>

<div class="options">
  <div class="wrapper discord desktop" (click)="openModal()">
    <div class="content">
      <ng-icon name="ionLogoDiscord"></ng-icon>
      <span class="title">Discord</span>
    </div>
  </div>

  <div class="wrapper discord mobile" (click)="openSheet()">
    <div class="content">
      <ng-icon name="ionLogoDiscord"></ng-icon>
      <span class="title">Discord</span>
    </div>
  </div>

  <a
    href="https://www.facebook.com/fsinfokoeln"
    target="_blank"
    referrerpolicy="no-referrer"
    rel="noreferrer"
    class="wrapper facebook"
  >
    <div class="content">
      <ng-icon name="ionLogoFacebook"></ng-icon>
      <span class="title">Facebook</span>
    </div>
  </a>

  <a
    href="https://www.instagram.com/fsinfokoeln/"
    target="_blank"
    referrerpolicy="no-referrer"
    rel="noreferrer"
    class="wrapper instagram"
  >
    <div class="content">
      <ng-icon name="ionLogoInstagram"></ng-icon>
      <span class="title">Instagram</span>
    </div>
  </a>

  <div class="wrapper mail" (click)="mailTo()">
    <div class="content">
      <ng-icon name="ionMailOutline"></ng-icon>
      <span class="title">Mail</span>
    </div>
  </div>
</div>
