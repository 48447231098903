import { ApplicationConfig, LOCALE_ID, importProvidersFrom } from '@angular/core';

import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { provideAnimations } from '@angular/platform-browser/animations';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';
import { routes } from './app-routing.module';

const MatModules = [
  MatToolbarModule,
  MatExpansionModule,
  MatButtonModule,
  MatPaginatorModule,
  MatTabsModule,
  MatSidenavModule,
  MatBottomSheetModule,
  MatDialogModule,
];

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserModule, ...MatModules, LazyLoadImageModule),
    { provide: LOCALE_ID, useValue: 'de' },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideRouter(
      routes,
      withInMemoryScrolling({ anchorScrolling: 'disabled' }),
      withComponentInputBinding()
    ),
    provideClientHydration(),
  ],
};
