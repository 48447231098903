import { Component, OnInit, ViewContainerRef, OnDestroy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { fromEvent, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Component({
    selector: 'app-kontakt-sheet',
    templateUrl: './kontakt-sheet.component.html',
    styleUrls: ['./kontakt-sheet.component.scss'],
    standalone: true,
})
export class KontaktSheetComponent implements OnDestroy, OnInit {
  private touchStart = 0;
  private subscriptions = new Array<Subscription>();

  constructor(
    public bottomRef: MatBottomSheetRef,
    private ref: ViewContainerRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      fromEvent<TouchEvent>(this.document, 'touchstart').subscribe((evt) => {
        if ((evt.target as HTMLElement)?.classList.contains('cdk-overlay-backdrop'))
          this.bottomRef.dismiss();
      }),
      fromEvent<TouchEvent>(this.ref.element.nativeElement, 'touchstart').subscribe((evt) => {
        this.touchStart = evt.changedTouches.item(0)?.pageY || 0;
      }),
      fromEvent<TouchEvent>(this.ref.element.nativeElement, 'touchmove')
        .pipe(throttleTime(50))
        .subscribe((evt) => {
          const touchMove = evt.changedTouches.item(0)?.pageY || 0;
          if (touchMove > this.touchStart) this.bottomRef.dismiss();
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
