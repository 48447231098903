import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-kontakt-modal',
    templateUrl: './kontakt-modal.component.html',
    styleUrls: ['./kontakt-modal.component.scss'],
    standalone: true
})
export class KontaktModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
