<h1>
  {{ inhalt.title }}

  <a
    href="/studium/{{ tabLink }}"
    title="In neuem Tab öffnen"
    class="button-open-in-new"
    target="_blank"
    ><ng-icon name="matOpenInNew"></ng-icon
  ></a>
</h1>

<markdown [content]="inhalt.content" class="content"></markdown>
