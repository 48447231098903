<div class="introduction">
  <h1>Rund ums <span class="underlined">Studium</span></h1>
  <h2>
    Dein Survivalguide<br class="mobile" />
    für BSI &amp; BIT
  </h2>
</div>

<div class="list desktop">
  @for (inhalt of studieninhalte$ | async; track inhalt.sluggedTitle) {
    <div class="list-item" (click)="openStudyContent(inhalt, 'desktop')">{{ inhalt.title }}</div>
  }
</div>

<div class="list mobile">
  @for (inhalt of studieninhalte$ | async; track inhalt.sluggedTitle) {
    <div class="list-item" (click)="openStudyContent(inhalt, 'mobile')">{{ inhalt.title }}</div>
  }
</div>
